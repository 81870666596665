<template>
  <div>
    <!-- scroll-target="#scrolling-techniques-7" -->
    <v-app-bar
      absolute
      :dense="$vuetify.breakpoint.mobile"
      color="blue lighten-5"
      class="ma-0"
    >
      <v-icon>mdi-api</v-icon>
      <v-toolbar-title class="ml-3" v-if="!$vuetify.breakpoint.mobile"
        >Log de Acesso Usuário</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-text-field
        v-if="mode === 'viewer'"
        hide-details
        dense
        filled
        class="text-field-transparent"
        v-model="search"
        persistent-placeholder
        placeholder="Digite as informações de pesquisa"
        light
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn>
    </v-app-bar>
    <div v-if="mode === 'viewer'">
      <hr class="ma-10" />
      <div class="filtroTexto">
        <v-card outlined color="#F7F7F7" class="ma-2">
          <v-row dense class="ma-2">
            <v-col dense cols="12" lg="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                dense
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Intervalo de datas"
                    dense
                    outlined
                    readonly
                    persistent-hint
                    append-icon="mdi-calendar"
                    @click="on"
                    @click:append="on.click"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  range
                  dense
                  locale="pt-br"
                  :min="dataMinima"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                v-model="filtroNome"
                label="Nome do Usuário"
                dense
                outlined
                clearable
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ma-2" justify="end">
            <v-col cols="12" lg="3" class="d-flex justify-end">
              <v-btn
                elevation="2"
                :icon="$vuetify.breakpoint.mobile"
                :small="$vuetify.breakpoint.mobile"
                class="pa-2 ml-2"
                color="#FFFFFF"
                @click="filtraServidor"
                v-tooltip.bottom-end="'Filtro realizado no servidor'"
              >
                {{ msgButton("Filtrar") }}
                <v-icon>mdi-magnify </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <hr class="ma-5" />
      <v-data-table
        dense
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="acessUsuarioLog"
        :options.sync="options"
        :server-items-length="totalNumberOfItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': 'Logs por página',
          pageText: '{0}-{1} de {2}',
          'items-per-page-options': [20, 20],
          'show-first-last-page': true,
        }"
      >
        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            elevation="2"
            icon
            @click="loadAccessCNPJ(item)"
            class="mr-2"
            v-tooltip.bottom-end="'Editar registro da tabela'"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            elevation="2"
            icon
            @click="loadAccessCNPJ(item, 'remove')"
            class=""
            v-tooltip.bottom-end="'Remove registro da tabela'"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>


<script>
// import PageTitle from "@/layouts/PageTitle.vue";
import { baseApiUrl, showError, showSucess } from "@/global";
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  name: "AdminAcess",

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.mode === "save" || this.mode === "insert") {
      if (!window.confirm("Deseja sair sem gravar os dados?")) {
        return;
      }
    }

    next();
  },

  computed: {
    ...mapState(["user", "isMenuVisible"]),
    heightScroll() {
      if (this.$vuetify.breakpoint.mobile) {
        return "height: 600px;";
      } else {
        return "height: 900px;";
      }
    },
    itemsWithIndex() {
      return this.items.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
    unsavedDataExist() {
      console.log("teste inicio");
      if (this.mode === "save" || this.mode === "insert") {
        console.log("teste true");
        return true;
      } else {
        console.log("teste false");
        return false;
      }
    },
    dateRangeText() {
      let intervaloDatas = [];
      if (this.dates.length >= 1) {
        this.ajustaDataMin();

        this.dates.forEach((element) => {
          intervaloDatas.push(moment(element).format("DD/MM/YYYY"));
        });
        return intervaloDatas.join(" ~ ");
      } else {
        return intervaloDatas;
      }
    },
  },
  data: function () {
    return {
      valid: false,
      index: 0,
      x: 0,
      y: 0,
      dates: [],
      filtroNome: "",
      dataMinima: "",
      totalNumberOfItems: 0,
      page: 1,
      totalPassengers: 0,
      numberOfPages: 0,
      dataInicial: moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ).format("DD/MM/YYYY"),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      menu2: false,

      options: {},
      loading: true,
      // numberOfPages: 0,
      rulesCNPJ: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 14) || "Tamanho de 14 caracteres",
        (value) => (value && value.length == 14) || "Digito inválido",
        (value) =>
          (!isNaN(parseFloat(value)) &&
            value >= 0 &&
            value <= 99999999999999) ||
          "Digito inválido. Somente números",
      ],
      rulesMAC: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 17) || "Tamanho de 17",
      ],
      rulesEmail21: this.rules,
      mode: "viewer",
      search: "",

      accessCNPJ: {},
      accessCNPJs: [],
      acessUsuarioLog: [],
      booleanYes: Boolean,
      headers: [
        {
          text: "Num. Registro",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "codigo",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: "Equipamento",
          align: "start",
          sortable: true,
          value: "equipamento",
        },
        {
          text: "Ip",
          align: "start",
          sortable: true,
          value: "ip",
        },

        {
          text: "Data Acesso",
          align: "start",
          sortable: false,
          value: "data",
          formatter: this.change,
        },
      ],
    };
  },

  watch: {
    options: {
      handler() {

         this.paginate();
      },
    },

    deep: true,
    date() {
      this.dataInicial = moment(this.date).format("DD/MM/YYYY");
    },
  },
  methods: {
    parseDate(date) {
      if (!this.dataInicial) return null;

      let [day, month, year] = date.split("/");
      day = String(day++);
      let datanova = Date.parse(
        year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0")
      );
      return datanova;
    },
    preventNav(event) {
      console.log("prevent");
      if (this.mode === "save" || this.mode === "insert") {
        //event.preventDefault()
        event.returnValue = "";
        console.log("prevent default");
      } else {
        console.log("prevent default");
        return;
      }
    },
    toTop() {
      document.getElementById("containerEdicao").scrollIntoView(0, 0);
      window.scrollTo(0, 0);
    },
    setMenuMobile() {
      if (this.$store.state.isMenuVisibleMobile) {
        this.$store.state.isMenuVisibleMobile = false;
      } else {
        this.$store.state.isMenuVisibleMobile = true;
      }
    },

    msgButton: function (value) {
      if (this.$vuetify.breakpoint.mobile) {
        return "";
      } else {
        return value;
      }
    },
    loadAccessCNPJs() {
      // window.scrollTo(0, 0);
      // this.$vuetify.goTo(0);
      //usuarios_equipamentos_log?limit=50&datainicial=2022-01-12 00:00:00&datafinal=2022-01-20 00:00:00&page=2&codigo=8
      console.log("axios busca api");
      const url = `${baseApiUrl}/usuarios_equipamentos_log?limit=${this.limitPage}&datainicial=2022-01-12 00:00:00&datafinal=2022-01-20 00:00:00`;
      axios.get(url).then((res) => {
        console.log(res.data);

        this.acessUsuarioLog = res.data.data;
        this.totalNumberOfItems = res.data.total;
        console.log("registros" + this.acessUsuarioLog.length);
        console.log("total" + this.totalNumberOfItems);
      });
    },
    filtraServidor() {
      this.options.page = 1;
      this.paginate();
    },
    paginate() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      const dataFiltroInicial = this.dates[0];
      const dataFiltroFinal = moment(Date.parse(this.dates[1])).add(2,'days').format("YYYY-MM-DD");

      if (!this.filtroNome) {
        this.filtroNome = "";
      }
      const url = `${baseApiUrl}/usuarios_equipamentos_log?limit=${itemsPerPage}&page=${pageNumber}&datainicial=${dataFiltroInicial}&datafinal=${dataFiltroFinal}&usuario=${this.filtroNome.toUpperCase()}`;

      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          console.log("registros" + this.acessUsuarioLog.length);
          this.loading = false;
          this.acessUsuarioLog = res.data.data;
          this.totalNumberOfItems = res.data.total;
          this.numberOfPages = Math.ceil(res.data.total / 10);
          let newArr1 = this.acessUsuarioLog.map((items, index) => ({
            ...items,
            index: (pageNumber - 1) * itemsPerPage + index + 1,
          }));
          this.acessUsuarioLog = newArr1;
        })
        .catch(() => {
           showError("Erro interno");
          this.loading = false;
          
          
        });
    },

    change(value) {
      // var d = new Date();
      //  var today = new Date(value - d.getTimezoneOffset() * 60000);

      var today = new Date(value);

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hh = String(today.getHours()).padStart(2, "0");
      var minutos = String(today.getMinutes()).padStart(2, "0");
      var ss = String(today.getSeconds()).padStart(2, "0");

      return dd + "/" + mm + "/" + yyyy + " " + hh + ":" + minutos + ":" + ss;
    },
    reset() {
      this.$refs.form.reset();
      this.accessCNPJ = {};
      this.loadAccessCNPJs();
      this.mode = "viewer";
    },
    save() {
      if (this.$refs.form.validate()) {
        const method = this.accessCNPJ.id ? "put" : "post";
        const id = this.accessCNPJ.id ? `/${this.accessCNPJ.id}` : "";
        this.accessCNPJ.userId = Number(this.user.id);

        axios[method](`${baseApiUrl}/access_cnpj${id}`, this.accessCNPJ)
          .then(() => {
            showSucess();
            this.reset();
          })
          .catch((error) => {
            showError(error);
          });
      } else {
        this.valid = false;
      }
    },
    remove() {
      const id = this.accessCNPJ.id;
      axios
        .delete(`${baseApiUrl}/access_cnpj/${id}`)
        .then(() => {
          showSucess();
          this.reset();
        })
        .catch(showError);
    },
    // loadAccessCNPJ(accessCNPJ, mode = "save") {
    loadAccessCNPJ(accessCNPJ, mode = "save") {
      this.mode = mode;
      this.accessCNPJ = { ...accessCNPJ };
      this.toTop();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(email));
      return re.test(email);
    },
    ajustaDataMin() {
      if (this.dates[0] && !this.dates[1]) {
        this.dataMinima = this.dates[0];
      } else {
        this.dataMinima = "";
      }
    },
    intervaloDatasInicial() {
      let dataInicial = new Date();
      dataInicial.setDate(dataInicial.getDate() - 6);
      this.dates.push(moment(dataInicial).format("YYYY-MM-DD"));
      let dataFinal = new Date();
      this.dates.push(String(moment(dataFinal).format("YYYY-MM-DD")));
    },
  },
  mounted() {
    this.intervaloDatasInicial();
    this.$store.state.isMenuVisibleMobile = true;
    // this.paginate();
    
  },
};
</script>

<style >
.texto .v-label {
  font-size: 1.3em;
}

.filtroTexto input {
  text-transform: uppercase;
}
.text-field-transparent .v-input__slot {
  background: transparent !important;
}
</style>