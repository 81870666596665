var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"ma-0",attrs:{"absolute":"","dense":_vm.$vuetify.breakpoint.mobile,"color":"blue lighten-5"}},[_c('v-icon',[_vm._v("mdi-api")]),(!_vm.$vuetify.breakpoint.mobile)?_c('v-toolbar-title',{staticClass:"ml-3"},[_vm._v("Log de Acesso Usuário")]):_vm._e(),_c('v-spacer'),(_vm.mode === 'viewer')?_c('v-text-field',{staticClass:"text-field-transparent",attrs:{"hide-details":"","dense":"","filled":"","persistent-placeholder":"","placeholder":"Digite as informações de pesquisa","light":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-spacer'),(_vm.mode === 'viewer')?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:('Menu geral do sistema'),expression:"'Menu geral do sistema'",modifiers:{"bottom-end":true}}],staticClass:"pa-2 ml-2",attrs:{"elevation":"2","icon":_vm.$vuetify.breakpoint.mobile,"small":_vm.$vuetify.breakpoint.mobile,"color":"secondary"},on:{"click":_vm.setMenuMobile}},[_vm._v(" "+_vm._s(_vm.msgButton("Menu"))+" "),(!_vm.$store.state.isMenuVisibleMobile)?_c('v-icon',[_vm._v("mdi-menu-down ")]):_c('v-icon',[_vm._v(" mdi-menu ")])],1):_vm._e()],1),(_vm.mode === 'viewer')?_c('div',[_c('hr',{staticClass:"ma-10"}),_c('div',{staticClass:"filtroTexto"},[_c('v-card',{staticClass:"ma-2",attrs:{"outlined":"","color":"#F7F7F7"}},[_c('v-row',{staticClass:"ma-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"dense":"","cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","dense":"","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Intervalo de datas","dense":"","outlined":"","readonly":"","persistent-hint":"","append-icon":"mdi-calendar","hide-details":"auto"},on:{"click":on,"click:append":on.click},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,1356488291),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","dense":"","locale":"pt-br","min":_vm.dataMinima},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Nome do Usuário","dense":"","outlined":"","clearable":"","hide-details":"auto"},model:{value:(_vm.filtroNome),callback:function ($$v) {_vm.filtroNome=$$v},expression:"filtroNome"}})],1)],1),_c('v-row',{staticClass:"ma-2",attrs:{"dense":"","justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","lg":"3"}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:('Filtro realizado no servidor'),expression:"'Filtro realizado no servidor'",modifiers:{"bottom-end":true}}],staticClass:"pa-2 ml-2",attrs:{"elevation":"2","icon":_vm.$vuetify.breakpoint.mobile,"small":_vm.$vuetify.breakpoint.mobile,"color":"#FFFFFF"},on:{"click":_vm.filtraServidor}},[_vm._v(" "+_vm._s(_vm.msgButton("Filtrar"))+" "),_c('v-icon',[_vm._v("mdi-magnify ")])],1)],1)],1)],1)],1),_c('hr',{staticClass:"ma-5"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","page":_vm.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.acessUsuarioLog,"options":_vm.options,"server-items-length":_vm.totalNumberOfItems,"loading":_vm.loading,"footer-props":{
        'items-per-page-text': 'Logs por página',
        pageText: '{0}-{1} de {2}',
        'items-per-page-options': [20, 20],
        'show-first-last-page': true,
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
        )),function(header){return {key:("item." + (header.value)),fn:function(ref){
        var header = ref.header;
        var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:('Editar registro da tabela'),expression:"'Editar registro da tabela'",modifiers:{"bottom-end":true}}],staticClass:"mr-2",attrs:{"elevation":"2","icon":""},on:{"click":function($event){return _vm.loadAccessCNPJ(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:('Remove registro da tabela'),expression:"'Remove registro da tabela'",modifiers:{"bottom-end":true}}],attrs:{"elevation":"2","icon":""},on:{"click":function($event){return _vm.loadAccessCNPJ(item, 'remove')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }